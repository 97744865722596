// const BASE_PATH = "https://raw.githubusercontent.com/techorbitals/techorbitals/refs/heads/main/assets";
const BASE_PATH = "/assets";

const botImage = `${BASE_PATH}/chat/bot.png`;

export const finalImage = {
  logo: `https://res.cloudinary.com/dhrjz8des/image/upload/v1731741591/logo_ka5t2i.webp`,
  home: {
    hero: `https://res.cloudinary.com/dhrjz8des/image/upload/v1731741597/hero_gxog7n.webp`,
    services: {
      web: `https://res.cloudinary.com/dhrjz8des/image/upload/v1731741598/web_dapbrh.webp`,
      seo: `https://res.cloudinary.com/dhrjz8des/image/upload/v1731741598/seo_oxyknu.webp`,
      ecommerce: `https://res.cloudinary.com/dhrjz8des/image/upload/v1731741599/ecommerce_vuvrfs.webp`,
    },
  },
  register: {
    background: `https://res.cloudinary.com/dhrjz8des/image/upload/v1731741595/register-background_upuwdh.webp`,
    msme: `https://res.cloudinary.com/dhrjz8des/image/upload/v1731741596/msme_patfou.webp`,
  },
  team: {
    heads: {
      purnesh: `https://res.cloudinary.com/dhrjz8des/image/upload/v1731741600/purnesh_rcovvj.webp`,
      prashant: `https://res.cloudinary.com/dhrjz8des/image/upload/v1731741600/prashant_yoghum.webp`,
      tushar: `https://res.cloudinary.com/dhrjz8des/image/upload/v1731741600/tushar_z6a80p.webp`,
    },
    sub: {
      aryan: `https://res.cloudinary.com/dhrjz8des/image/upload/v1731741603/aryan_fzfgks.webp`,
      muskaan: `https://res.cloudinary.com/dhrjz8des/image/upload/v1731741603/muskaan_epwkoi.webp`,
      anshi: `https://res.cloudinary.com/dhrjz8des/image/upload/v1731741603/anshi_ymi9to.webp`,
      abhishek: `https://res.cloudinary.com/dhrjz8des/image/upload/v1731741602/abhishek_pnhlsd.webp`,
      shreyash: `https://res.cloudinary.com/dhrjz8des/image/upload/v1731741602/shreyash_qf3jj6.webp`,
      samridhi: `https://res.cloudinary.com/dhrjz8des/image/upload/v1731741601/samridhi_v7joeh.webp`,
      aman: `https://res.cloudinary.com/dhrjz8des/image/upload/v1731741601/aman_gqvnx9.webp`,
      janvi: `https://res.cloudinary.com/dhrjz8des/image/upload/v1731741601/janvi_csujda.webp`,
    },
  },
  hub: {
    email: `https://res.cloudinary.com/dhrjz8des/image/upload/v1731741604/email_npn7zl.webp`,
    rocket: `https://res.cloudinary.com/dhrjz8des/image/upload/v1731741603/rocket_rjtmi5.webp`,
    "digital-services": `https://res.cloudinary.com/dhrjz8des/image/upload/v1731741604/digital-services_vew1kf.webp`,
  },
  page: {
    banner: {
      "get-started": `https://res.cloudinary.com/dhrjz8des/image/upload/v1731741593/get-started_rywkf8.jpg`,
      career: `https://res.cloudinary.com/dhrjz8des/image/upload/v1731741596/career_tnh63x.webp`,
      about: `https://res.cloudinary.com/dhrjz8des/image/upload/v1731741594/about_xwypeq.webp`,
      service: `https://res.cloudinary.com/dhrjz8des/image/upload/v1731741592/service_rofsve.webp`,
      contact: `https://res.cloudinary.com/dhrjz8des/image/upload/v1731741591/contact_yzquvv.webp`,
      blog: `https://res.cloudinary.com/dhrjz8des/image/upload/v1731741596/blog_u1uxce.webp`,
      reviews: `https://res.cloudinary.com/dhrjz8des/image/upload/v1731741594/reviews_fisaku.webp`,
      "help-center": `https://res.cloudinary.com/dhrjz8des/image/upload/v1731741596/help-center_sycq1p.webp`,
      price:
        "https://res.cloudinary.com/dhrjz8des/image/upload/v1731741593/price-banner_bt4cbs.webp",
    },
    about: {
      1: `https://res.cloudinary.com/dhrjz8des/image/upload/v1731741592/about-1_xznmsh.webp`,
      2: `https://res.cloudinary.com/dhrjz8des/image/upload/v1731741593/about-2_n3r2mh.webp`,
    },
  },
  footer: {
    cyclist: `https://res.cloudinary.com/dhrjz8des/image/upload/v1731741599/cyclist_lopi1j.gif`,
    volks: `https://res.cloudinary.com/dhrjz8des/image/upload/v1731741599/volks_ej8snr.gif`,
    bg: `https://res.cloudinary.com/dhrjz8des/image/upload/v1731741598/footer_bg_qqm5ae.webp`,
  },
  spacial: {
    image: "/special-section.svg",
  },
};

export { botImage };
